const config = {
  msal: {
    auth: {
      clientId: 'a31bd067-102b-4b22-8acb-6899b4c50f44',
      authority:
        'https://devdigicust.b2clogin.com/tfp/devdigicust.onmicrosoft.com/B2C_1_signin_signup/',
      knownAuthorities: ['devdigicust.b2clogin.com'],
      redirectUri: `${window.origin}/`,
    },
    cache: {
      cacheLocation: 'localStorage', // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  },
  msalRequest: {
    scopes: ['openid a31bd067-102b-4b22-8acb-6899b4c50f44 offline_access'],
  },
  API_URL: 'https://dev.api.digicust.com',
  // API_URL: 'http://localhost:3005',
};

export default config;
