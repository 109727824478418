import { FC } from 'react';
import { ReactComponent as LoaderIcon } from '../resources/svg/loader.svg';
import { Tooltip } from 'antd';
interface PropsType extends React.CSSProperties {
  center?: boolean;
  fullPage?: boolean;
  small?: boolean;
  title?: string;
}

const DCSpinner: FC<PropsType> = ({
  marginTop = 0,
  center,
  fullPage,
  small,
  title,
}): JSX.Element => {
  const size = small ? 24 : 44;

  const style: React.CSSProperties = {
    marginTop,
    display: 'flex',
    justifyContent: 'center',
  };

  if (center) {
    style.width = '100%';
  }

  if (fullPage) {
    style.width = '100vw';
    style.height = '100vh';
    style.marginTop = 300;
  }

  return (
    <Tooltip title={title}>
      <div id="loader" style={style}>
        <div style={{ width: size, height: size }}>
          <LoaderIcon />
        </div>
      </div>
    </Tooltip>
  );
};

export default DCSpinner;
