import axios from 'axios';
import { MemberRoles, MembershipUser, Permissions } from 'digicust_types';
import { GENERIC_URL } from './constants';

export class PermissionsRepository {
  public static async inviteNewUser({
    customerId,
    projectId,
    projectIds,
    userEmail,
    role,
  }: {
    customerId: string;
    projectId: string;
    projectIds?: string[];
    userEmail: string;
    role: MemberRoles;
  }): Promise<boolean> {
    try {
      const response = await axios.post(
        `${GENERIC_URL}/api/${customerId}/${projectId}/permissions/invite`,
        { projectIds, userEmail, role },
      );

      return response.data;
    } catch (error) {
      console.error(error);
      throw Error();
    }
  }

  public static async getUserDetails(
    customerId: string,
    projectId: string,
    userId: string,
  ): Promise<MembershipUser> {
    try {
      const response = await axios.get(
        `${GENERIC_URL}/api/${customerId}/${projectId}/permissions/user/${userId}`,
      );

      return response.data;
    } catch (error) {
      console.error(error);
      throw Error();
    }
  }

  public static async getUsers(
    customerId: string,
    projectId: string,
    role: MemberRoles | 'all' = 'all',
  ): Promise<MembershipUser[]> {
    try {
      const response = await axios.get(
        `${GENERIC_URL}/api/${customerId}/${projectId}/permissions/users/${role}`,
      );

      return response.data;
    } catch (error) {
      console.error(error);
      throw Error();
    }
  }

  public static async getOrgAdmins(
    customerId: string,
    projectId: string,
  ): Promise<MembershipUser[]> {
    try {
      const response = await axios.get(
        `${GENERIC_URL}/api/${customerId}/${projectId}/permissions/org-admins`,
      );

      return response.data;
    } catch (error) {
      console.error(error);
      throw Error();
    }
  }

  public static async changeUserPermissions({
    customerId,
    projectId,
    userId,
    permissions,
    enabledStrategies,
  }: {
    customerId: string;
    projectId: string;
    userId: string;
    permissions: Permissions[];
    enabledStrategies: string[];
  }): Promise<MembershipUser[]> {
    try {
      const response = await axios.patch(
        `${GENERIC_URL}/api/${customerId}/${projectId}/permissions/${userId}/permissions`,
        { permissions, enabledStrategies },
      );

      return response.data;
    } catch (error) {
      console.error(error);
      throw Error();
    }
  }

  public static async changeUserRole({
    customerId,
    projectId,
    userId,
    role,
    oldRole,
  }: {
    customerId: string;
    projectId: string;
    userId: string;
    role: MemberRoles;
    oldRole: MemberRoles;
  }): Promise<MembershipUser[]> {
    try {
      const response = await axios.patch(
        `${GENERIC_URL}/api/${customerId}/${projectId}/permissions/${userId}/access/${role}`,
        { oldRole, projectId },
      );

      return response.data;
    } catch (error) {
      console.error(error);
      throw Error();
    }
  }

  public static async removeUserPermission({
    customerId,
    projectId,
    userId,
    groupId,
  }: {
    customerId: string;
    projectId: string;
    userId: string;
    groupId: string;
  }): Promise<MembershipUser[] | undefined> {
    try {
      const response = await axios.delete(
        `${GENERIC_URL}/api/${customerId}/${projectId}/permissions/${userId}/access/${groupId}`,
      );

      return response.data;
    } catch (error) {
      console.error(error);
      throw Error();
    }
  }
}
